.l-site-header {
	padding: var(--padding-rythm);
	padding-bottom: calc(var(--padding-rythm) * 2);
	position: relative;
	z-index: 100;
	background-size: 100% auto;

	&--logo {
		width: 143px;
		img {
			width: 100%;
			height: auto;
			display: block;
		}
	}

	&--menu-trigger {
		transition: all 300ms ease-in-out;
		position: absolute;
		top: var(--padding-rythm);
		right: var(--padding-rythm);
		z-index: 2;
		color: black;
		display: flex;
		text-transform: uppercase;
		font-weight: 800;
		align-items: center;
		.menu-icon {
			margin-top: -2px;
		}
	}

	&--navigation {
		transition: all 300ms ease-in-out;
		width: 100vw;
		height: calc(100vh - #{$mobile-header-height});
		position: fixed;
		top: $mobile-header-height;
		right: 0;
		opacity: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: var(--padding-rythm);
		z-index: 1;
		pointer-events: none;
		font-size: var(--fs-h3);
		font-family: $heading-font;
		text-transform: uppercase;
		background: $off-white;
		&-item + &-item {
			margin-top: var(--padding-rythm-sm);
		}
		&.is-active {
			pointer-events: all;
			opacity: 1;
		}
	}
}

@include from($bp-medium) {
	.l-site-header {
		padding: var(--padding-rythm);
		display: flex;
		justify-content: space-between;

		&--logo {
			width: 17vw;
		}

		&--menu-trigger {
			display: none;
		}

		&--navigation {
			position: static;
			padding: 0;
			width: auto;
			height: auto;
			pointer-events: all;
			opacity: 1;
			background: none;
			display: flex;
			flex-direction: row;
			font-size: var(--fs-body-lg);
			&-item + &-item {
				margin-top: 0;
				margin-left: var(--padding-rythm-sm);
			}
			&-item {
				transition: border-bottom-color 200ms ease-in-out;
				border-bottom: 3px solid transparent;
				&:hover,
				&:focus {
					border-bottom-color: $off-white-dark;
				}
				&:active {
					border-bottom-color: black;
				}
			}
		}
	}
}
