.l-site-footer {
	padding: var(--padding-rythm);
	padding-top: calc(var(--padding-rythm-lg) * 2);
	&--social {
		a {
			display: inline-block;
			text-transform: uppercase;

			margin-right: var(--padding-rythm-sm);
		}
	}
	&--legal {
		margin-top: var(--padding-rythm-sm);
	}
}
