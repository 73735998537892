/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1400px;

/**
 * Font definitions
 */
$defaultFontSize: 1;

$body-font: "IvarDisplay", serif;
$heading-font: "IvarFine", serif;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Raw Colors
 */
$dark-grey: #c5bdbd;
$middle-grey: #808285; // #a7a9ac - put the similar colors you've replaced here
$light-grey: #efeff0; // #eaebec

$off-white: #fdf1de;
$off-white-dark: darken($off-white, 5%);

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

/**
 * Misc
 */
$max-width: ($bp-medium * 1px);
$box-shadow: 0 0 40px rgba(black, 0.1);
$mobile-header-height: 115px;

$heading-x-offset: -0.1em;

$border-radius: 8px;

:root {
	--fs-fold: 10.9vw;
	--fs-h2: 8.2vw;
	--fs-h3: 24px;
	--fs-body: 16px;
	--fs-body-lg: 18px;
	--fs-lede: 23px;

	--padding-rythm-lg: 7.6vw;
	--padding-rythm: 4.4vw;
	--padding-rythm-sm: 10px;
}

@include from-md {
	:root {
		--fs-fold: min(10.9vw, 80px);
		--fs-h2: min(2.77vw, 40px);
		--fs-h3: 24px;
		--fs-body-lg: 20px;
		--fs-lede: 33px;

		--padding-rythm-lg: 7.6vw;
		--padding-rythm: max(4.166vw, 60px);
		--padding-rythm-sm: 18px;
	}
}
