.video-block {
	position: relative;

	iframe {
		z-index: 1;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: black;
	}
	&--cover {
		transition: opacity 300ms ease-in-out;
		z-index: 3;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: none;
		opacity: 0.8;
		border: none;

		&:after {
			content: "";

			position: absolute;
			top: 50%;
			left: 50%;

			width: 0;
			height: 0;
			border-top: 45px solid transparent;
			border-bottom: 45px solid transparent;

			border-left: 70px solid white;

			text-decoration: none;

			transform: translate(-50%, -50%) translateZ(0);
		}
	}
	&--image {
		transition: opacity 300ms ease-in-out;
		opacity: 0.9;
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:hover,
	&:focus {
		.video-block {
			&--image,
			&--cover {
				opacity: 1;
			}
		}
	}

	&.has-video &--cover {
		opacity: 0;
		pointer-events: none;
	}
}
