body {
	font-size: var(--fs-body);
	letter-spacing: 0.05em;
	font-family: $body-font;
	line-height: 1.4;
	font-feature-settings: "pnum";
	font-feature-settings: "lnum";

	color: black;
	background: $off-white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

.lede {
	font-size: 1.2em;
}

.section-title {
	text-transform: uppercase;
	font-size: var(--fs-h2);
	margin-top: var(--padding-rythm-lg);
	margin-bottom: calc(var(--padding-rythm) / 2);
}

.title {
	font-size: var(--fs-fold);
	margin-bottom: var(--padding-rythm);
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 1em;
	}
	> * + h3 {
		margin-top: 1.2em;
	}
	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	h3:not([class]) {
		font-weight: bold;
		font-size: var(--fs-h3);
	}

	a:not([class]) {
		@include base-link;
		display: inline;
		word-break: break-all;
	}

	ul,
	ol {
		padding-left: 1em;
	}

	ul {
		li {
			list-style: disc;
		}
	}

	.responsive-object {
		position: relative;
		iframe,
		img {
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}
}
