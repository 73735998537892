.gallery {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: var(--padding-rythm-sm);
	width: 100%;
	margin: var(--padding-rythm-sm) 0;
	&--item {
		display: block;
		width: 100%;
		height: auto;
		&__full {
			grid-column-end: span 6;
		}
		&__half {
			grid-column-end: span 3;
		}
		&__third {
			grid-column-end: span 2;
		}
		&-caption {
			padding-top: calc(var(--padding-rythm-sm) / 2);
			font-size: 0.7em;
		}
	}

	&--item + &--item {
		margin-top: var(--padding-rythm-sm);
		@supports (display: grid) {
			margin-top: 0;
		}
	}
	&--caption {
		grid-column-end: span 6;
		font-style: italic;
		text-align: center;
		padding: var(--padding-rythm-sm);
		padding-top: 0;
	}
}

@include from($bp-small) {
	.gallery {
		grid-column-gap: var(--padding-rythm-sm);
		grid-row-gap: var(--padding-rythm-sm);
	}
}
