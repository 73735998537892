.l-streamfield {
	font-size: var(--fs-body-lg);
	> * {
		margin-bottom: 0;
		+ * {
			margin-top: var(--padding-rythm);
		}
		+ .section-title {
			margin-top: calc(var(--padding-rythm) * 2);
		}
	}
	> .title {
		margin-bottom: calc(var(--padding-rythm) * 0.5);
	}
	> .btn {
		margin-right: var(--padding-rythm);
	}
	&__constrained {
		max-width: 1200px;
	}
}

@include from-md {
	.l-streamfield {
		.section-title {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			margin-right: 0;
		}
	}
}
