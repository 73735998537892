.form-field {
	position: relative;
	width: 100%;

	&--field,
	&--label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	&--field {
		border: 0 none;
		padding: calc(var(--padding-rythm-sm) / 1.5);
		width: 100%;
		appearance: none;
		border: 0 none;
		background: white;
		color: black;
		height: 40px;
		font-size: var(--fs-body-lg);
		border-radius: 20px;
		&::placeholder {
			color: black;
			opacity: 0.5;
		}
	}

	&--boolean-group {
		> * + * {
			margin-top: calc(var(--padding-rythm-sm) / 2);
		}
	}

	&--label {
		margin-bottom: 12px;
		&-required {
			color: red;
		}
	}

	&--error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: 10px;
		}
	}

	&--help {
		width: 100%;
		padding: 12px;
	}
}

@include from($bp-medium) {
	.form-field {
		&--field {
			height: var(--padding-rythm);
			border-radius: calc(var(--padding-rythm) / 2);
			padding: calc(var(--padding-rythm-sm) / 2)
				calc(var(--padding-rythm-sm) * 1.2);
		}
	}
}
