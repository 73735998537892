.l-home-fold {
	position: relative;
	&--title {
		font-size: var(--fs-fold);
		margin-top: calc(var(--padding-rythm-lg) * 2);
		div {
			position: relative;
			img {
				position: absolute;
				top: 40%;
				left: -2%;
				transform: translateY(-50%);
				width: 92%;
			}
		}
	}
	&--intro {
		display: grid;
		grid-gap: var(--padding-rythm);
		grid-template-columns: repeat(2, 1fr);
		font-size: var(--fs-lede);
		margin-top: var(--padding-rythm-lg);
		&-text {
			align-self: center;
			grid-column: 1/3;
		}
		picture {
			display: block;
		}
	}
}

@include from-md {
	.l-home-fold {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		&--title {
			margin-top: 0;
			div {
				position: relative;
				img {
					width: 100%;
				}
			}
		}
		&--intro {
			grid-template-columns: repeat(4, 1fr);
			.rich-text {
				align-self: center;
			}
		}
	}
}
