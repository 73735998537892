.image-link {
	display: block;
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		font-size: var(--fs-tiny);
		padding-top: calc(var(--padding-rythm-sm) / 2);
	}
}
