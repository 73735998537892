.artwork-caption {
	font-size: var(--fs-body);
	&--title {
		font-size: var(--fs-body-lg);
	}
	&--sale {
		display: flex;
		align-items: center;

		&--price {
		}
		&--sold {
		}
		&--purchase {
			@include base-link;
			margin-left: var(--padding-rythm-sm);
		}
	}
}

@include from-md {
	.artwork-caption {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&--title {
		}
		&--sale {
			display: flex;
			align-items: center;
		}
	}
}
