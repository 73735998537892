@font-face {
	font-family: "IvarFine";
	src: url("../fonts/IvarFine.woff2") format("woff2"),
		url("../fonts/IvarFine.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "IvarDisplay";
	src: url("../fonts/IvarDisplay.woff2") format("woff2"),
		url("../fonts/IvarDisplay.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
