.card {
	display: block;
	position: relative;

	&--title {
		text-transform: uppercase;
		font-size: var(--fs-h2);
		margin-top: var(--padding-rythm-sm);
		margin-bottom: calc(0.5 * var(--padding-rythm-sm));
	}

	&--categories {
		margin-bottom: calc(0.4 * var(--padding-rythm-sm));
		span {
			text-decoration: underline;
			+ span {
				margin-left: 0.5em;
			}
		}
	}

	&--image {
		display: block;
	}

	transition: transform 200ms ease-in-out;
}
@include to-md {
	.card-list {
		.card + .card {
			margin-top: calc(2 * var(--padding-rythm-sm));
		}
	}
}

@include from-md {
	.card {
		> * {
			position: relative;
			z-index: 2;
		}
		&:before {
			transition: opacity 150ms ease-in-out;
			content: "";
			position: absolute;
			top: calc(-1 * var(--padding-rythm-sm));
			left: calc(-1 * var(--padding-rythm-sm));
			width: calc(100% + 2 * var(--padding-rythm-sm));
			height: calc(100% + 2 * var(--padding-rythm-sm));
			background: $off-white-dark;
			opacity: 0;
			z-index: 1;
		}

		&:hover,
		&:active,
		&:focus {
			&:before {
				opacity: 1;
			}
		}
	}
	.card-list {
		display: grid;
		grid-gap: var(--padding-rythm);
		grid-template-columns: repeat(4, 1fr);
	}
}
