.l-site-content {
	padding: 0 var(--padding-rythm);
}

@include to($bp-medium) {
	.l-site-content {
		min-height: calc(100vh - (85px + 79px));
		.l-streamfield {
			margin-top: var(--padding-rythm-sm);
		}
	}
}

@include from($bp-medium) {
	.l-site-content {
		padding: 0 var(--padding-rythm);
	}
}
